import React, { Fragment, memo, useState } from 'react';
import { css } from '@emotion/react';
import { Button, Divider, TextField, Typography, dividerClasses } from '@mui/material';
import { FcGoogle } from 'react-icons/fc';
import { flex } from '~/modules/AppLayout/FlexGridCss';
import { fontWeight600 } from '~/css/font';
import { Loading } from '~/components/Loading';
import { SnackbarMessage } from '~/components/SnackbarWarning';
import { globalRed } from '~/modules/AppLayout/Colors';
import { getTodayTrialCode } from '~/modules/auth/hashUtls';
import { useRouter } from 'next/router';
import { useText } from '~/hooks/useText';
import { useAppLocalStorage } from '~/hooks/useAppLocalStorage';
import { useMeStore } from '~/modules/SDK/me/useMeStore';
import { useFirebaseMeState } from '~/modules/auth/containers/useFirebaseMeState';
import { authDialogState } from '~/modules/auth/containers/authDialogStore';
import { useSnapshot } from 'valtio';
import { fr_agents } from '~/pages/heineken_template/_fr/fr_agents';
const wayContainerCss = css `
  ${flex.v.default};
  gap: 8px;
`;
export const LoginForm = memo(function LoginForm(props) {
    const agentConfig = useSnapshot(fr_agents).config;
    return (<div css={css `
        ${flex.v.default}
        padding: 32px;

        & > .${dividerClasses.root} {
          margin: 16px 0;
        }
      `}>
      {agentConfig.loginApproachAllow.thirdparty === true && (<Fragment>
          <span data-testid={testids.ThirdpartyButtons}></span>
          <ThirdpartyButtons />
          <Divider />
        </Fragment>)}

      {agentConfig.loginApproachAllow.password === true && (<Fragment>
          <span data-testid={testids.PasswordTexts}></span>
          <PasswordTexts />
          <Divider />
        </Fragment>)}

      {/**
         * # @deprecated {@link WhyThisDeprecated.TrialCode}
         *
         * ```tsx
         *   {agentConfig.loginApproachAllow.trialCode === true && (
         *       <Fragment>
         *         <span data-testid={testids.TrialCode}></span>
         *         <TrialCode />
         *       </Fragment>
         *     )}
         * ```
         */}
    </div>);
});
export const ThirdpartyButtons = memo(function ThirdpartyButtons(props) {
    return (<div css={wayContainerCss}>
      <Typography variant='h5'>登入</Typography>

      <div css={flex.v.default}>
        <GoogleLoginButton />
      </div>
    </div>);
});
export const GoogleLoginButton = memo(function GoogleLoginButton() {
    const auth = useFirebaseMeState.useContainer();
    const router = useRouter();
    return (<Button size={'large'} variant='outlined' startIcon={<FcGoogle />} onClick={() => {
            auth.acts.loginWithGoogle().then(() => {
                router.reload();
            });
        }}>
      透過 Google 登入
    </Button>);
});
export const PasswordTexts = memo(function PasswordTexts(props) {
    const account = useText();
    const password = useText();
    const auth = useFirebaseMeState.useContainer();
    const agentConfig = useMeStore(state => state.agentConfig);
    return (<div css={wayContainerCss}>
      <Typography variant='h5'>一般登入</Typography>

      <TextField size='small' variant='outlined' label='Account' value={account.value} onChange={account.acts.onChange}/>

      <TextField size='small' variant='outlined' type='password' label='Password' value={password.value} onChange={password.acts.onChange} onKeyDown={e => {
            if (e.key === 'Enter') {
                auth.acts.loginWithMailAndPassword((account.value ?? '') + agentConfig.emailSuffix, password.value ?? '');
            }
        }}/>
      {auth.error && (<p css={css `
            color: ${globalRed.r500};
            ${fontWeight600};
            margin: 0 0 16px;
          `}>
          發生錯誤：請確認帳號密碼
        </p>)}

      <Button size='large' variant='contained' color='primary' disabled={auth.isAuthorizing} onClick={() => auth.acts.loginWithMailAndPassword((account.value ?? '') + agentConfig.emailSuffix, password.value ?? '')}>
        {auth.isAuthorizing ? (<Loading css={css `
              width: 24px;
              height: 24px;
            `}/>) : ('  登入')}
      </Button>
    </div>);
});
/** ## @deprecated 試用功能已廢棄，由啟動碼 `/api/activation/apply` 功能取代 */
export const TrialCode = memo(function TrialCode(props) {
    const [, setLocalTrialCode] = useAppLocalStorage('localTrialCode', '');
    const [errorOpen, setErrorOpen] = useState(false);
    const trialcode = useText();
    const handleClose = (event, reason) => {
        if (reason === 'clickaway')
            return;
        setErrorOpen(false);
    };
    const validateCode = () => {
        // 回傳[sha256, 數字]，sha256存localStorage
        const todayResult = getTodayTrialCode();
        if (trialcode.value === todayResult[1]) {
            useMeStore.setState({ isValidTrialUser: true });
            authDialogState.open = false;
            setLocalTrialCode(todayResult[0]);
        }
        else {
            setErrorOpen(true);
        }
    };
    return (<div css={wayContainerCss}>
      <Typography variant='caption'>若有序號，輸入此驗證登入</Typography>

      <TextField size='small' variant='outlined' value={trialcode.value} onChange={trialcode.acts.onChange}/>

      <Button size='small' variant='outlined' color='success' onClick={validateCode}>
        驗證序號
      </Button>
      <SnackbarMessage open={errorOpen} closeDelegate={handleClose} message='序號錯誤'/>
    </div>);
});
export const testids = {
    ThirdpartyButtons: 'ThirdpartyButtons',
    PasswordTexts: 'PasswordTexts',
    TrialCode: 'TrialCode',
};
