import { entries, isNull } from 'lodash';
import mixpanel from 'mixpanel-browser';
import Router from 'next/router';
import { useRef } from 'react';
import useConstant from 'use-constant';
import { ENV } from '~/configs/ENV';
import { GA_TRACKING_ID } from '~/configs/GA_TRACKING_ID';
import { debugAPI } from '~/modules/SDK/debug/debugAPI';
import { useMeStore } from '~/modules/SDK/me/useMeStore';
import { fr_agents } from '~/pages/heineken_template/_fr/fr_agents';
import { fr_me } from '~/pages/heineken_template/_fr/fr_me';
import { __DEV__ } from '~/utils/__DEV__';
import dayAPI from '~/utils/dayAPI';
mixpanel.init('dc0220b0e950d9853c5c043f397590b5', {
    debug: __DEV__ ? true : false,
    track_pageview: true,
    persistence: 'localStorage',
    persistence_name: 'futuresai.mixpanel_analytics',
});
export const gaAPI = {
    initPageViewOnRouteChange() {
        Router.events.on('routeChangeComplete', url => gaAPI.pageview(url));
    },
    pageview(url) {
        gtag('config', GA_TRACKING_ID, {
            page_path: url,
        });
    },
    event(events) {
        const uid = fr_me._ourMe?.uid || useMeStore.getState().meUserState?.uid || __UNSET__;
        const name = fr_me.me.displayName ||
            useMeStore.getState().meInfoState?.name ||
            useMeStore.getState().meUserState?.name ||
            __UNSET__;
        const email = fr_me._firebaseMe?.email || useMeStore.getState().meUserState?.email || __UNSET__;
        const defaultsEventData = {
            uid: uid,
            userName: name,
            userEmail: email,
            commitBranch: ENV.BRANCH,
            commitHash: ENV.COMMITHASH,
            commitDate: ENV.VERSION,
            agentName: fr_agents.agent,
            agentProduct: fr_agents.agentProduct,
            agentProductName: fr_agents.product,
        };
        for (const [eventName, eventData] of entries(events)) {
            mixpanel.track(eventName, {
                ...defaultsEventData,
                ...(isNull(eventData) ? undefined : eventData),
            });
            debugAPI.ga.log(`${eventName}`, eventData);
        }
    },
    /** 花費了多久時間？ */
    useTimeSpent: () => {
        const hasCalled = useRef(false);
        const initialTimeAt = useConstant(() => {
            return dayAPI();
        });
        return {
            calc: (predicate) => {
                if (hasCalled.current)
                    return;
                if (predicate() !== true)
                    return;
                const now = dayAPI();
                hasCalled.current = true;
                return {
                    done: (callback) => {
                        callback({ ms: Math.abs(now.get('ms') - initialTimeAt.get('ms')) });
                    },
                };
            },
        };
    },
};
const __UNSET__ = '__UNSET__';
