import { Urlu } from '~/modules/SDK/app/urlu/Urlu';
export class LineNotifyAPI extends Urlu {
    push = new Urlu.Query((payload) => {
        const formData = new FormData();
        Object.entries(payload).map(([k, v]) => {
            formData.append(k, v);
        });
        return this.request.axios.post('https://line-notify.futures-ai.com/push', formData);
    });
    notify = new Urlu.Query((payload) => {
        const formData = new FormData();
        Object.entries(payload).map(([k, v]) => {
            formData.append(k, v);
        });
        return this.request.axios.post('https://line-notify.futures-ai.com/notify', formData);
    });
}
